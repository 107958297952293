import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    isLoggedIn: 0,
    openedPageName: "",
    isSidebarOpen: 0,
    showStickyHeader: 0,
    showStickyHeaderHome: 0,
  },
};

export const UserSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    populate_user: (state, action) => {
      switch (action.payload.type) {
        case "isLoggedIn":
          state.value.isLoggedIn = action.payload.sol;
          break;
        case "openPageName":
          state.value.openedPageName = action.payload.sol;
          break;
        case "isSidebarOpen":
          state.value.isSidebarOpen = action.payload.sol;
          break;
        case "showStickyHeader":
          state.value.showStickyHeader = action.payload.sol;
          break;
        case "showStickyHeaderHome":
          state.value.showStickyHeaderHome = action.payload.sol;
          break;
      }
    },
  },
});
export const { populate_user } = UserSlice.actions;
export default UserSlice.reducer;
