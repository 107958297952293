import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCjEccI9jpJbQTZW-lq5rrLWJvvvT8ikPE",
  authDomain: "isellconsole-90d1f.firebaseapp.com",
  projectId: "isellconsole-90d1f",
  storageBucket: "isellconsole-90d1f.firebasestorage.app",
  messagingSenderId: "652879352460",
  appId: "1:652879352460:web:232d906e5a732d69a161a9",
  measurementId: "G-EMGLSC0Y5D"
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth=getAuth(app)
