import React, { useState, useEffect } from "react";
import Home from "../Pages/Home";
import Products from "../Pages/Products";
import SearchPage from "../Pages/SearchPage";
import OrderDescription from "../Pages/OrderDescription";
import Orders from "../Pages/Orders";
import Cart from "../Pages/Cart";
import AuthenticationPage from "../Pages/AuthenticationPage";
import Sidebar from "./Sidebar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";
import FloatingFooter from "./FloatingFooter";
import FloatingFooterConfirmOrder from "./FloatingFooterConfirmOrder";
import ProductDescription from "../Pages/ProductDescription";
import { useSelector, useDispatch } from "react-redux";
import { populate_user } from "./Redux/UserSlice";
import { useCookies } from "react-cookie";
import { createContext, useContext } from 'react';
import { TestUserFunction } from "./Redux/TestuserSlice";
import axios from "axios";
import { PatchingCategoryData } from "./Redux/CategorySlice";
import { auth } from "../firebaseInit";
import { onAuthStateChanged } from "firebase/auth";
import { PatchingProducts } from "./Redux/ProductsSlice";
function RoutingComponent() {
  const [cookies, setCookie] = useCookies([
    "cart",
    "showStickyHeader",
    "showStickyHeaderCart",
  ]);


  const [user, setUser] = useState(null)
  const [currentState, setCurrentState] = useState(true)
  const [UserDeatils, setUserDeatils] = useState(null)
  // useEffect(() => {
  
  //   let result = localStorage.getItem("mobileNumber")
  //     if (result != null) {
  //       setUser(result)
  //       FetchingSpecificUser(result)
  //     } 
  // }, [])

  // const dispatch = useDispatch()

  // async function FetchingSpecificUser(number) {
  //   try {
  //     axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getSpecificUser/' + number)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           if (response.data.length > 0) {
  //             setUserDeatils(response.data[0])
  //             dispatch(TestUserFunction({ type: "id", value: response.data[0].id }))
  //             dispatch(TestUserFunction({ type: "name", value: response.data[0].name }))
  //             dispatch(TestUserFunction({ type: "mobileNumber", value: response.data[0].mobileNumber }))
  //             dispatch(TestUserFunction({ type: "address", value: response.data[0].address }))
  //             dispatch(TestUserFunction({ type: "delivery_address", value: response.data[0].delivery_address }))
  //             dispatch(TestUserFunction({ type: "cart", value: response.data[0].cart }))
  //             console.log(response.data);
  //             setUser(response.data)
  //           } else {
  //             setUser(null)
  //           }

  //         }
  //       }).catch((error) => {
  //         console.log(error.message);
  //       })
  //   } catch (error) {
  //   }
  // }
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      {
        if (window.scrollY > 100) {
          setCookie("showStickyHeader", 1);
          setCookie("showStickyHeaderCart", 1);
        } else {
          setCookie("showStickyHeader", 0);
          setCookie("showStickyHeaderCart", 0);
        }
      }
    });
  }, []);
  return (
    <div className={`flex flex-col  bg-white`}>
      <Router>
      {!window.location.href.includes("/searchPage") ? <Header /> : null}
        {/* {(user == undefined || user == null) ? */}
          {/* : */}
          <Routes>
            <Route path="/" element={<AuthenticationPage /> } />
            <Route path="/home" element={<Home />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/products" element={<Products />} />
            <Route path="/productDescription" element={<ProductDescription />} />
            <Route path="/searchPage" element={<SearchPage />} />
            <Route path="/orderDescription" element={<OrderDescription />} />
          </Routes>
        {/* } */}
      </Router>
    </div>
  );
}
export default RoutingComponent;
