export const onAddToCart = (User, _obj, userid) => {
    let temp = []
    if (User.cart == null) {
        const data = {
            "I": _obj.product_id,
            "N": _obj.product_name,
            "Q": 1,
            "P": _obj.sale_prize == 0 ? _obj.regular_prize : _obj.sale_prize,
            "T": _obj.thumbnail,
            "V": userid,
            "C": ''
        }
        temp.push(data)
    } else {
        temp = [...JSON.parse(User.cart)]
        let Idx = temp.findIndex(e => e.I == _obj.product_id)
        if (Idx == -1) {
            const data = {
                "I": _obj.product_id,
                "N": _obj.product_name,
                "Q": 1,
                "P": _obj.sale_prize == 0 ? _obj.regular_prize : _obj.sale_prize,
                "T": _obj.thumbnail,
                "V": userid,
                "C": ''
            }
            temp.push(data)
        }
    }
    return temp;
};
export const QuantityClicked = (User, _obj, type) => {
    let temp = [...JSON.parse(User.cart)]
    const cart = temp.find(e => e.I == _obj.product_id);
    if (type == 'Inc') {
        let plus = (cart.Q) + 1
        cart.Q = plus
    } else {
        let minus = (cart.Q) - 1
        if (minus == 0) {
            const idx = temp.findIndex(e => e.I == _obj.product_id);
            temp.splice(idx, 1)
        } else {
            cart.Q = minus
        }
    }
    return temp
}