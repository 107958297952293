import React, { useEffect, useState } from "react";
import Pens from "../Assets/CataloguePictures/Pens.jpg";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Sidebar from "../Components/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import { auth } from "../firebaseInit";
import AuthenticationPage from "./AuthenticationPage";
import { signOut } from "firebase/auth";
function Orders() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["cart", "openSidebar"]);
  const returnTheCompleteMonthName = (value) => {
    switch (value) {
      case 0:
        return "January";
      case 1:
        return "February";
      case 2:
        return "March";
      case 3:
        return "April";
      case 4:
        return "May";
      case 5:
        return "June";
      case 6:
        return "July";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "October";
      case 10:
        return "November";
      case 11:
        return "December";
    }
  };
  const returnThePaddedValues = (value) => {
    if (value.toString().length < 2) {
      return value.toString().padStart(2, 0);
    } else {
      return value.toString();
    }
  };

  useEffect(() => {
    FetchingOrdersData()
  }, [])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const [orderData, setOrderData] = useState([])
  const User = useSelector((e) => e.testUser.Initialuser)
  useEffect(() => {
    if (User.id == null) {
      navigate('/home')
    }
  }, [])



  async function FetchingOrdersData(_id) {
    try {
      axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/getOrders/' + User.id)
        .then((response) => {
          if (response.status == 200) {
            setOrderData(response.data.reverse())
            console.log(response.data);
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    FetchingSpecificUser()
  }, [])
  const [vendorId, setvendorId] = useState(null)
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split('/')[2]
    try {
      await axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/' + domainname.trim())
        .then((response) => {
          if (response.status == 200) {
            // dispatch(PatchingProducts(response.data))
            // FetchingProducts(response.data[0].id)
            setvendorId(response.data[0].id)
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {
      console.log(error.message);
    }
  }
  const returnTheQtyCost = (purpose, User) => {
    if (purpose === "Qty") {
      let count = 0
      User.forEach(element => {
        count += element.Q
      });
      return count
    } else if (purpose === "Cost") {
      let count = 0
      User.forEach(element => {
        count += (element.P * element.Q)
      });
      return count;
    }

  };


  function SignoutFunction() {
    // signOut(auth).then(() => {
    localStorage.setItem("mobileNumber", '')
    navigate('/')
    // &&
    //   <AuthenticationPage />
    // }).catch((error) => {
    //   console.log(error.message);
    // });
  }
  const sidebarShow = useSelector(e => e.sidebarStatus.initialValue)
  return (
    <div className="mainLayout bg-white relative ">
      <div className="columnHolder 2xl:w-[40%] xl:w-[40%] lg:w-[40%] md:w-[95%] sm:w-[95%] w-[95%] self-center py-[20px]">
        <div className="rowHolder w-[98%] self-center">
          <div className="text-black text-3xl font-normal">Orders</div>
          <div
            className="heading text-base cursor-pointer underline font-medium "
            onClick={() => SignoutFunction()}
          >
            Logout
          </div>
        </div>
        <div className="gridHolderOrderDescription h-full w-[98%] self-center  mt-5">
          {orderData.length > 0 ? orderData.map((item, index) => (
            <div
              key={index}
              className="w-full min-h-[100px] columnHolder justify-center my-1 items-center py-[0.25%] cursor-pointer"
              onClick={() => navigate("/orderDescription", { state: { data: item } })}
            >
              <div
                key={index}
                className="w-full h-full columnHolder justify-center items-center rounded-lg  bg-white shadow-md"
              >
                <div className="rowHolder justify-between items-center self-center w-[98%] py-[0%]">
                  <div className="text-sm text-black">Order {item.id}</div>
                  <div className="text-base text-black">{returnTheQtyCost("Qty", JSON.parse(item.cart))} Items</div>
                </div>
                <div className="rowHolder justify-between items-center self-center w-[98%] ">
                  <div className="text-sm text-black">{new Date(item.posteddate).getUTCDate() + ' ' + (new Date(item.posteddate).toLocaleDateString('en-US', { month: 'long' })) + ' ' + new Date(item.posteddate).getUTCFullYear()}</div>
                  <div className="text-sm text-black text-transparent">{returnTheQtyCost("Qty", JSON.parse(item.cart))} Items</div>
                </div>
                <div className="rowHolder justify-between items-center self-center w-[98%] ">
                  <div className="text-[16px] text-black">₹{returnTheQtyCost("Cost", JSON.parse(item.cart))}</div>
                  <div
                    disabled={true}
                    className="rectangleButtons px-3 py-[4px] bg-transparent rounded-md text-white text-[12px]"
                  >
                    {item.Payment_status == null ? null : <div
                      className={` text-white ${item.Payment_status == 1 ? 'bg-red-600 ' : item.Payment_status == 2 ? 'bg-green-600 ' : 'bg-primaryColor'} py-2 h-max rounded-md flex flex-col items-center justify-center font-semibold px-5 text-xs`}>
                      <h6 className=' text-sm'>{item.Payment_status == 1 ? 'On hold' : item.Payment_status == 2 ? 'Processing' : 'Completed'}</h6>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          )) : <label>No Orders Yet</label>}
        </div>
      </div>
      {sidebarShow == 1 ? <Sidebar /> : null}
    </div>
  );
}
export default Orders;
