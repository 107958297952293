import React, { useState, useEffect, act } from "react";
import Sidebar from "../Components/Sidebar";
import FloatingFooter from "../Components/FloatingFooter.js";
import { useNavigate, useLocation, useNavigation } from "react-router-dom";
import PreviousIcon from "../Assets/PreviousIcon.png";
import NextIcon from "../Assets/NextIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { onAddToCart, QuantityClicked } from "../Components/ReusableFunction.js";
import { TestUserFunction } from "../Components/Redux/TestuserSlice.js";
import { UpdateTheData } from "../APi/ApiCollection.js";
function ProductDescription() {
  const User = useSelector((e) => e.testUser.Initialuser)
  const location = useLocation();
  const userId = location.state.userid
  const indexval = location.state.indexValue
  const productArray = location.state.productArray
  const [productIndex, setProductIndex] = useState(indexval)
  const navigate = useNavigate()
  useEffect(() => {
    if (User.id == null) {
      navigate(-1)
    }
  }, []);
  const [currentIndex, setCurrentIndex] = useState(0);
  const onClickedPreviousNext = (action) => {
    let tempIndex = productIndex;
    setCurrentIndex(0)
    if (action === "Previous") {
      tempIndex -= 1;
      if (tempIndex < 0) {
        tempIndex = productArray.length - 1;
      }
      setProductIndex(tempIndex);
    } else if (action === "Next") {
      tempIndex += 1;
      if (tempIndex > productArray.length - 1) {
        tempIndex = 0;
      }
      setProductIndex(tempIndex);
    }
  };
  const dispatch = useDispatch()
  const onAddToCartClicked = async (_obj) => {
    const temp = onAddToCart(User, _obj, userId)
    const data1 = {
      "cart": JSON.stringify(temp)
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'cart', value: JSON.stringify(temp) }))
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const QuantityManagement = async (_obj, type) => {
    const temp = QuantityClicked(User, _obj, type)
    const data1 = {
      "cart": temp.length == 0 ? null : JSON.stringify(temp)
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'cart', value: temp.length == 0 ? null : JSON.stringify(temp) }))
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const sidebarShow = useSelector(e => e.sidebarStatus.initialValue)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={`mainLayout relative lg:h-screen`}>
      {productArray != undefined && indexval != undefined &&
        <div className="max-w-[1700px] w-full h-full flex flex-col px-5 md:px-10 mt-5 mx-auto">
          <div className="columnHolder 2xl:hidden xl:hidden lg:hidden relative overflow-auto">
            {productArray.length !== 1 ? <div
              className="bg-white columnHolder border-ButtonColor w-[30px] h-[30px] justify-center items-center absolute left-[10px]  top-[150px] rounded-full cursor-pointer"
              onClick={() => onClickedPreviousNext("Previous")}
            >
              <img src={PreviousIcon} className="squareIcons" />
            </div> : null}
            {productArray.length !== 1 ? <div
              className="bg-white columnHolder w-[30px] h-[30px] justify-center items-center absolute right-[10px] top-[150px] rounded-full cursor-pointer"
              onClick={() => onClickedPreviousNext("Next")}
            >
              <img src={NextIcon} className="squareIcons" />
            </div> : null}
            <div className="flex flex-col">
              <img
                src={'https://storage.googleapis.com/cloudsb/ProductImage/' + JSON.parse(productArray[productIndex].banner_url)[currentIndex]}
                className="2xl:max-w-[400px] xl:max-w-[400px] md:max-w-[400px] w-full max-h-[400px] object-fit"
              />
              <div className="flex flex-row overflow-auto w-full my-3 gap-x-3 items-center">
                {
                  productArray[productIndex].banner_url.length > 0 && JSON.parse(productArray[productIndex].banner_url).map((e, index) =>
                    <div key={index} className="rounded-full cursor-pointer p-[3px] flex-none border-2 border-dashed border-ButtonColor ">
                      <img onClick={() => setCurrentIndex(index)} className="w-12 h-12 rounded-full object-cover" src={'https://storage.googleapis.com/cloudsb/ProductImage/' + e} />
                    </div>
                  )
                }
              </div>
            </div>
            <div className="columnHolder p-[10px] gap-y-[10px]  bg-TWhiteColor">
              <div className=" text-black text-left font-bold">
                {productArray[productIndex].product_name}
              </div>
              <div className="opacity-80 flex flex-row items-center text-left font-bold">
                Price: {productArray[productIndex].sale_prize == 0 ? <span className="ml-2 text-ButtonColor"> ₹{productArray[productIndex].regular_prize}</span> :
                  <div className="flex flex-row items-center ml-2 space-x-3">
                    <span className="line-through text-red-600">₹{productArray[productIndex].regular_prize}</span>
                    <span className="text-ButtonColor">₹{productArray[productIndex].sale_prize}</span>
                  </div>}
              </div>
              <span className=" text-black opacity-80 font-semibold text-sm"> {productArray[productIndex].product_desc}</span>
              <span className={`${productArray[productIndex].product_Quantity == 0 ? 'text-red-600' : 'text-green-600'}  opacity-80 font-semibold text-sm`}> {productArray[productIndex].product_Quantity == 0 ? 'Out of Stock' : productArray[productIndex].product_Quantity + ' Products Available'}</span>
              {/* <div className="heading text-left font-semibold">
                MOQ:<span className="text-ButtonColor font-bold text-lg"> 1 Set</span>
              </div> */}

              {User.cart == null ?
                <div
                  className="shortContent mt-2 text-center py-3 w-full rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                  onClick={() =>
                    onAddToCartClicked(productArray[productIndex])
                  }
                >
                  ADD TO CART
                </div> :
                JSON.parse(User.cart).findIndex((e) => e.I == productArray[productIndex].product_id) == -1 ? (
                  <div
                    className="shortContent text-center py-3 w-full rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                    onClick={() =>
                      onAddToCartClicked(productArray[productIndex])
                    }
                  >
                    ADD TO CART
                  </div>
                ) : (
                  <div className="rowHolder w-full  justify-between items-center border border-ButtonColor  rounded-lg">
                    <div
                      className="shortContent text-center py-3 w-[30%]  rounded-l-lg font-semibold text-black bg-[#D3D3D3]"
                      onClick={() =>
                        QuantityManagement(
                          productArray[productIndex], "Dec"
                        )
                      }
                    >
                      -
                    </div>
                    <div className="heading  font-normal  w-[40%]">
                      {JSON.parse(User.cart).find((e) => e.I == productArray[productIndex].product_id).Q}
                    </div>
                    <div
                      className="shortContent text-center py-3 w-[30%]   rounded-br-lg font-semibold text-white bg-ButtonColor "
                      onClick={() =>
                        QuantityManagement(
                          productArray[productIndex], "Inc"
                        )
                      }
                    >
                      +
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div className=" lg:grid grid-cols-2 hidden w-[98%] self-center relative ">
            <div className="flex flex-col items-center">
              <img
                src={'https://storage.googleapis.com/cloudsb/ProductImage/' + JSON.parse(productArray[productIndex].banner_url)[currentIndex]}
                className=" lg:w-[600px] rounded-md  w-full  lg:h-[600px] h-[400px] object-center"
              />
              <div className="flex flex-row mt-3 gap-x-3 items-center">
                {
                  productArray[productIndex].banner_url.length > 0 && JSON.parse(productArray[productIndex].banner_url).map((e, index) =>
                    <div key={index} className="rounded-full cursor-pointer p-[3px] border-2 border-dashed border-ButtonColor ">
                      <img onClick={() => setCurrentIndex(index)} className="w-12 h-12 rounded-full object-cover" src={'https://storage.googleapis.com/cloudsb/ProductImage/' + e} />
                    </div>
                  )
                }
              </div>
            </div>
            {productArray.length !== 1 ? <a title="Previous Product"
              className="columnHolder border-2 border-ButtonColor border-dashed justify-center items-center absolute -left-[40px]  top-[250px] rounded-full cursor-pointer"
              onClick={() => onClickedPreviousNext("Previous")}
            >
              <img src={'https://catalogue-cdn.quicksell.co/s/assets/left-angle-black-4.png'} className="squareIcons h-8 w-8" />
            </a> : null}
            {productArray.length !== 1 ? <a title="Next Product"
              className=" columnHolder border-2 border-ButtonColor border-dashed justify-center items-center absolute right-0 top-[250px] rounded-full cursor-pointer"
              onClick={() => onClickedPreviousNext("Next")}
            >
              <img src={'https://catalogue-cdn.quicksell.co/s/assets/right-angle-black-2.png'} className="squareIcons h-8 w-8" />
            </a> : null}
            <div className="columnHolder p-[10px] gap-y-[10px]">
              <div className="heading text-left text-black font-bold">
                {productArray[productIndex].product_name}
              </div>
              <div className="heading text-left flex flex-row items-center font-semibold">
                Price: {productArray[productIndex].sale_prize == 0 ? <span className="ml-2 text-ButtonColor"> ₹{productArray[productIndex].regular_prize}</span> :
                  <div className="flex flex-row items-center ml-2 space-x-3">
                    <span className="line-through text-red-600"> ₹{productArray[productIndex].regular_prize}</span>
                    <span className="text-ButtonColor">₹{productArray[productIndex].sale_prize}</span>
                  </div>}
              </div>
              <span className=" text-black opacity-80 font-semibold text-base"> {productArray[productIndex].product_desc}</span>
              <span className=" text-red-600 opacity-80 font-semibold text-sm"> {productArray[productIndex].product_Quantity == 0 ? 'Out of Stock' : productArray[productIndex].product_Quantity + ' Products Available'}</span>
              {/* <div className="heading text-left font-semibold">
                MOQ: <span className="text-ButtonColor font-bold text-xl">1 Set</span>
              </div> */}

              {User.cart == null ?
                <div
                  className="shortContent mt-2 w-max cursor-pointer text-center py-3 px-3 rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                  onClick={() =>
                    onAddToCartClicked(productArray[productIndex])
                  }
                >
                  ADD TO CART
                </div> :
                JSON.parse(User.cart).findIndex((e) => e.I == productArray[productIndex].product_id) == -1 ? (
                  <div
                    className="shortContent cursor-pointer text-center p-3 w-max rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                    onClick={() =>
                      onAddToCartClicked(productArray[productIndex])
                    }
                  >
                    ADD TO CART
                  </div>
                ) : (
                  <div className="rowHolder w-[170px] justify-between items-center border border-ButtonColor rounded-lg">
                    <div
                      className="shortContent cursor-pointer  text-center py-3 w-[30%]  rounded-l-lg font-semibold text-black bg-[#D3D3D3]"
                      onClick={() =>
                        QuantityManagement(
                          productArray[productIndex], "Dec"
                        )
                      }
                    >
                      -
                    </div>
                    <div className="heading  font-normal  w-[40%]">
                      {JSON.parse(User.cart).find((e) => e.I == productArray[productIndex].product_id).Q}
                    </div>
                    <div
                      className="shortContent cursor-pointer  text-center py-3 w-[30%] rounded-r-lg font-semibold text-white bg-ButtonColor "
                      onClick={() =>
                        QuantityManagement(
                          productArray[productIndex], "Inc"
                        )
                      }
                    >
                      +
                    </div>
                  </div>
                )}
            </div>
          </div>
          {/* <div className="border mx-3 my-3" /> */}
          {/* {productArray != undefined && productArray.length > 0 ?
          <>
            <div className="text-lg text-left w-full mb-3 mt-5 font-bold text-H1TextColor self-center">More Products</div>
            <div className="rowHolder gap-5 justify-start overflow-x-auto mb-20">
              {productArray.map((item, index) => (
                <div className="flex  cursor-pointer flex-col mb-2 w-[200px] flex-none" key={index}>
                  <div
                    className="catalogueSubParent"
                  // onClick={() => navigate("/ProductDescription")}
                  >
                    <img
                      className="w-full min-[325px]:h-[180px] min-[426px]:h-[230px] object-fill rounded-t-lg"
                      src={'https://storage.googleapis.com/cloudsb/ProductImage/' + item.thumbnail}
                    />
                    <div className="heading px-2 cursor-pointer text-left text-sm">{item.product_name}</div>
                    <div className="font-bold px-2 text-base text-ButtonColor ">₹{item.sale_prize}</div>
                    {User.cart == null ?
                      <div
                        className="shortContent text-center py-3 w-full rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                        onClick={() =>
                          onAddToCartClicked(item)
                        }
                      >
                        ADD TO CART
                      </div> :
                      JSON.parse(User.cart).findIndex((e) => e.I == item.product_id) == -1 ? (
                        <div
                          className="shortContent text-center py-3 w-full rounded-lg font-semibold text-white bg-ButtonColor duration-200 hover:bg-HoverColor"
                          onClick={() =>
                            onAddToCartClicked(item)
                          }
                        >
                          ADD TO CART
                        </div>
                      ) : (
                        <div className="rowHolder w-full  justify-between items-center border border-ButtonColor rounded-lg">
                          <div
                            className="shortContent text-center py-3 w-[30%]  rounded-l-lg font-semibold text-black bg-[#D3D3D3]"
                            onClick={() =>
                              QuantityManagement(
                                item, "Dec"
                              )
                            }
                          >
                            -
                          </div>
                          <div className="heading  font-normal  w-[40%]">
                            {JSON.parse(User.cart).find((e) => e.I == item.product_id).Q}
                          </div>
                          <div
                            className="shortContent text-center py-3 w-[30%]   rounded-br-lg font-semibold text-white bg-ButtonColor "
                            onClick={() =>
                              QuantityManagement(
                                item, "Inc"
                              )
                            }
                          >
                            +
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              ))}

            </div>
          </> : null} */}

        </div>}
      {sidebarShow == 1 ? <Sidebar /> : null}
      {User.cart != null && JSON.parse(User.cart).findIndex(e => e.V == userId) != -1 ? <FloatingFooter /> : null}
    </div>
  );
}
export default ProductDescription;
