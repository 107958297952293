import { createSlice } from "@reduxjs/toolkit";
const SidebarSlice=createSlice({
    name:'sidebar',
    initialState:{
        initialValue:0
    },
    reducers:{
        SidebarFunction:(state,params)=>{
            state.initialValue=params.payload
        }
    }
})
export const {SidebarFunction}=SidebarSlice.actions;
export default SidebarSlice.reducer;