import React, { useState } from "react";
import CloseIcon from "../Assets/CloseIcon.png";
import DownArrowIcon from "../Assets/DownArrow.png";
import DemoProfilePicture from "../Assets/DemoProfilePicture.jpg";
import { useDispatch } from "react-redux";
import { populate_user } from "./Redux/UserSlice";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { SidebarFunction } from "./Redux/SidebarSlice";
function Sidebar() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["openSidebar"]);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const dispatch = useDispatch();
  const returnThePaddedValues = (value) => {
    if (value.toString().length < 2) {
      return value.toString().padStart(2, 0);
    } else {
      return value.toString();
    }
  };
  const getTheCurrentDateTime = (purpose) => {
    switch (purpose) {
      case "DateTime":
        return (
          new Date().getFullYear() +
          "-" +
          returnThePaddedValues(new Date().getMonth() + 1) +
          "-" +
          returnThePaddedValues(new Date().getDate()) +
          " " +
          returnThePaddedValues(new Date().getHours()) +
          ":" +
          returnThePaddedValues(new Date().getMinutes()) +
          ":" +
          returnThePaddedValues(new Date().getSeconds())
        );
      case "Date":
        return (
          new Date().getFullYear() +
          "-" +
          returnThePaddedValues(new Date().getMonth() + 1) +
          "-" +
          returnThePaddedValues(new Date().getDate())
        );
    }
  };
  return (
    <div
      className=" sm:hidden h-full columnHolder absolute top-0 bg-white z-[999]"
      style={{ overscrollBehavior: "none" }}
    >
      <div className="w-full h-full bg-white opacity-[0.7] absolute" />
      <div className=" w-[250px] h-full bg-white 2xl:hidden xl:hidden lg:hidden columnHolder sticky left-0 z-[100] p-[20px]">
        <div className="rowHolder justify-end">
          <img
            src={CloseIcon}
            className="squareIcons cursor-pointer "
            onClick={() => dispatch(SidebarFunction(0))}
          />
        </div>
        <div onClick={() => setOpenSubMenu(!openSubMenu)} className="rowHolder justify-around my-[20px] border-b-2">
          <div className="shortContent">My Account</div>
          <img
            src={DownArrowIcon}
            className="w-[20px] h-[20px] object-contain cursor-pointer"  
          />
        </div>
        {openSubMenu == true ? (
          <div
            className="rowHolder justify-around border-b-2 cursor-pointer"
            onClick={() => {
              dispatch(SidebarFunction(0))
              navigate("/orders");
            }}
          >
            <div className="shortContent">Order History</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default Sidebar;
