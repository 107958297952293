import React, { useEffect, useRef, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { FaRegFilePdf } from "react-icons/fa";
import { Margin, usePDF } from 'react-to-pdf';
import Sidebar from "../Components/Sidebar";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import Pdfpage from "../Components/Pdfpage";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
function OrderDescription() {
  const navigate = useNavigate();
  const location = useLocation()
  const datas = location.state.data
  console.log(datas);


  const returnTheQtyCost = (purpose, User) => {
    if (purpose === "Qty") {
      let count = 0
      User.forEach(element => {
        count += element.Q
      });
      return count
    } else if (purpose === "Cost") {
      let count = 0
      User.forEach(element => {
        count += (element.P * element.Q)
      });
      return count;
    }
  }
  const sidebarShow = useSelector(e => e.sidebarStatus.initialValue)
  const User = useSelector((e) => e.testUser.Initialuser)

  useEffect(() => {
    if (User.id == null) {
      navigate('/home')
    }
  }, [])
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  return (
    <div className="mainLayout relative">
      <div className="columnHolder z-50  bg-white relative mt-5 2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-full sm:w-full w-full self-center">
        <div className="rowHolder py-4  justify-start gap-2 w-[95%] mx-auto items-center">
          <div
            className="columnHolder rounded-full  justify-center items-center cursor-pointer"
            onClick={() => navigate(-1)}>
            <GoArrowLeft className="squareIcons text-ButtonColor font-bold w-8 h-8" />
          </div>
          <div className="text-black text-lg">Back to all orders</div>
        </div>
        <div className="rowHolder w-[95%] self-center">
          <div className="columnHolder gap-2 px-[0%] w-[50%]">
            <div className="lg:text-3xl text-2xl text-left">Order {datas.id}</div>
            <div className="rowHolder justify-start flex  gap-2">
              {/* <div className=" bg-ButtonColor py-2 h-max rounded-md flex flex-col items-center justify-center text-white font-semibold px-5 text-xs">
                COMPLETED
              </div> */}
              {datas.Payment_status == null ? null : <div
                className={` text-white ${datas.Payment_status == 1 ? 'bg-red-600 ' : datas.Payment_status == 2 ? 'bg-green-600 ' : 'bg-primaryColor'} py-2 h-max rounded-md flex flex-col items-center justify-center font-semibold px-5 text-xs`}>
                <h6 className=' text-sm'>{datas.Payment_status == 1 ? 'On hold' : datas.Payment_status == 2 ? 'Processing' : 'Completed'}</h6>
              </div>}
              {/* <div className=" h-max flex px-5 py-2 rounded-md flex-col items-center justify-center bg-red-400 text-white  text-xs">
                UNPAID
              </div> */}
            </div>
          </div>
          <div className="columnHolder items-center justify-center">
            <div className="text-black text-2xl lg:text-3xl font-bold ">₹{returnTheQtyCost("Cost", JSON.parse(datas.cart))}</div>
            <div className="text-black text-base lg:text-base">{new Date(datas.posteddate).getUTCDate() + ' ' + (new Date(datas.posteddate).toLocaleDateString('en-US', { month: 'long' })) + ' ' + new Date(datas.posteddate).getUTCFullYear()}</div>
          </div>
        </div>
        <div className="rowHolder w-[95%] mt-5 self-center">
          <div onClick={reactToPrintFn} className="bg-white hover:bg-grayColor duration-200 cursor-pointer flex border flex-row gap-2 items-center justify-center py-3 px-5 rounded-md shadow-md ">
            <FaRegFilePdf className="w-5 h-5 text-[#dc6969]" />
            <h4 className="text-black text-base font-semibold">Download PDF</h4>
          </div>
        </div>
        <div className="columnHolder bg-white mt-[2%] w-[95%] rounded-lg self-center p-5 shadow-md">
          <div className="text-lg font-bold text-left">Billing Details</div>
          <div className="text-base  text-black mt-1">{JSON.parse(datas.address).N}</div>
          <div className="text-base  text-black">{JSON.parse(datas.address).email}</div>
          <div className="text-base  text-black">{User.mobileNumber}</div>
        </div>
        <div className="columnHolder bg-white p-5 mt-[2%] w-[95%] rounded-lg self-center shadow-md">
          <div className="text-lg font-bold text-left">
            Additional Details
          </div>
          <div className="text-base  text-black mt-1">Customer Name : {JSON.parse(User.delivery_address)[datas.delivery_address].CusName}</div>
          <div className="text-base  text-black mt-1">Mobile Number : {JSON.parse(User.delivery_address)[datas.delivery_address].CusMobile}</div>
          <div className="text-base  text-black mt-1">Address : {JSON.parse(User.delivery_address)[datas.delivery_address].Ad1} {JSON.parse(User.delivery_address)[datas.delivery_address].Ad2}</div>
          <div className="text-base  text-black ">City : {JSON.parse(User.delivery_address)[datas.delivery_address].CusCity}, {JSON.parse(User.delivery_address)[datas.delivery_address].CusState}</div>
          <div className="text-base  text-black ">Pincode : {JSON.parse(User.delivery_address)[datas.delivery_address].pincode}</div>
        </div>
        <div className="columnHolder z-50 bg-white w-[95%]  self-center border rounded-md shadow-md  mt-[2%]">
          <div className="text-xl px-5 text-black mt-1 font-semibold  w-full self-center">
            Your order items
          </div>
          <div className="gridHolderOrderDescription ">
            {JSON.parse(datas.cart).map((item, index) => (
              <div
                key={index}
                className="w-full h-[100px] flex flex-col justify-center items-center border-b"
              >
                <div className="rowHolder px-5 justify-between items-center self-center w-full">
                  <div className="rowHolder w-full justify-start items-center gap-2">
                    <img
                      src={'https://storage.googleapis.com/cloudsb/ProductImage/' + item.T}
                      className="squareImages w-[75px] h-[75px]"
                    />
                    <div className="columnHolder">
                      <div className="text-base text-H1TextColor font-bold">{item.N}</div>
                      <div className="text-sm text-black">Qty: {item.Q}</div>
                    </div>
                  </div>
                  <div className="text-ButtonColor text-xl font-semibold">₹{item.P * item.Q}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="rowHolder z-50 bg-white w-[95%]  self-center border rounded-md shadow-md  mt-[2%] p-3 mb-20">
          <div className="text-black text-2xl font-bold">Total</div>
          <div className="text-black text-2xl font-bold">₹{returnTheQtyCost("Cost", JSON.parse(datas.cart))}</div>
        </div>
      </div>
      {/* <div className="columnHolder top-0 relative mt-5 self-center" > */}
      <div className=" absolute z-0 lg:w-[60%] lg:max-w-full self-center w-full mx-auto top-10 lg:p-10 " >
        <Pdfpage data={datas} returnTheQtyCost={returnTheQtyCost} reference={contentRef}/>
      </div>
      {/* </div> */}
      {sidebarShow == 1 ? <Sidebar /> : null}
    </div>
  );
}
export default OrderDescription;
