import { createSlice } from "@reduxjs/toolkit";
const ProductsSlice = createSlice({
    name: 'Products',
    initialState: {
        ProductsInitial: null
    },
    reducers: {
        PatchingProducts: (state, params) => {
            state.ProductsInitial = params.payload
        }
    }
})

export const {PatchingProducts}=ProductsSlice.actions
export default ProductsSlice.reducer