import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import WhatsappIcon from "../Assets/WhatsappIcon.png";
import { Checked, Close, Delete, PlusIcon, Video } from "../Assets/icons";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../Components/Sidebar";
import unChecked from '../Assets/unChecked.png'
import { RiPencilFill } from "react-icons/ri";
import { UpdateTheData } from "../APi/ApiCollection";
import { TestUserFunction } from "../Components/Redux/TestuserSlice";
import FloatingFooterConfirmOrder from "../Components/FloatingFooterConfirmOrder";
import axios from "axios";
import { BiEdit, BiEditAlt } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
function Cart() {
  const user1 = useSelector((state) => state.userComponent.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const User = useSelector((e) => e.testUser.Initialuser)
  useEffect(() => {
    if (User && User.address != '') {
      updateingState()
    }
    if (User.cart != null && User.cart != '') {
      [...Array(JSON.parse(User.cart).length)].fill('')
      console.log(JSON.parse(User.cart).length);      
      for (let i = 0; i < JSON.parse(User.cart).length; i++) {
        handleCommentValueChange(i, JSON.parse(User.cart)[i].C)
      }
    }
  }, [])

  const [comment, setComment] = useState([])
  const handleCommentValueChange = (index, newValue) => {
    const newValues = [...comment];
    newValues[index] = newValue;
    setComment(newValues);
  };

  useEffect(() => {
    if (User.cart == null) {
      navigate('/home')
    }
  }, [])

  async function updateingState() {
    if (User.address != null && User.address != '') {
      setName(JSON.parse(User.address).N)
      setB_Name(JSON.parse(User.address).b_name)
      setEmail(JSON.parse(User.address).email)
    }
    if (User.delivery_address != null && User.delivery_address != '') {
      let position = JSON.parse(User.delivery_address)[0].Position
      console.log(position);
      setCus_Name(JSON.parse(User.delivery_address)[position].CusName)
      setAddress1(JSON.parse(User.delivery_address)[position].Ad1)
      setAddress2(JSON.parse(User.delivery_address)[position].Ad2)
      setMobileNumber(JSON.parse(User.delivery_address)[position].CusMobile)
      setDeliveryCity(JSON.parse(User.delivery_address)[position].CusCity)
      setDeliveryState(JSON.parse(User.delivery_address)[position].CusState)
      setPincode(JSON.parse(User.delivery_address)[position].pincode)
      setGSTNumber(JSON.parse(User.delivery_address)[position].GSTNumber)
    }

  }
  useEffect(() => {
    FetchingSpecificUser()
  }, [])
  const [VendorData, setVendorData] = useState({})
  async function FetchingSpecificUser() {
    let domainname = window.location.href.split('/')[2]
    try {
      await axios.get('https://cbdconsole-6zwsgvs5iq-el.a.run.app/specificVendor/domainName/' + domainname.trim())
        .then((response) => {
          if (response.status == 200) {
            // dispatch(PatchingProducts(response.data))
            // FetchingProducts(response.data[0].id)
            setVendorData(response.data[0].id)
          }

        }).catch((error) => {
          console.log(error.message);
        })
    } catch (error) {
      console.log(error.message);
    }
  }


  const returnTheQtyCost = (purpose) => {
    if (User.cart != null) {
      if (purpose === "Qty") {
        let count = 0
        JSON.parse(User.cart).forEach(element => {
          if (element.V == VendorData) {
            count += element.Q
          }
        });
        return count
      } else if (purpose === "Cost") {
        let count = 0
        JSON.parse(User.cart).forEach(element => {
          if (element.V == VendorData) {
            count += (element.P * element.Q)
          }
        });
        return count;
      }
    }

  };


  const QuantityManagement = async (_obj, type) => {
    let temp = [...JSON.parse(User.cart)]
    const cart = temp.find(e => e.I == _obj.I);
    if (type == 'Inc') {
      let plus = (cart.Q) + 1
      cart.Q = plus
    } else {
      let minus = (cart.Q) - 1
      if (minus == 0) {
        const idx = temp.findIndex(e => e.I == _obj.I);
        temp.splice(idx, 1)
      } else {
        cart.Q = minus
      }
    }
    const data1 = {
      "cart": temp.length == 0 ? null : JSON.stringify(temp)
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'cart', value: temp.length == 0 ? null : JSON.stringify(temp) }))
        if (temp.findIndex(e => e.V == VendorData) == -1) {
          navigate(-1)
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const [name, setName] = useState('')
  const [b_Name, setB_Name] = useState('')
  const [email, setEmail] = useState('')
  const [cus_Name, setCus_Name] = useState('')
  async function PatchingTheAddress() {
    if (name.length > 0 && b_Name.length > 0 && email.length > 0 && cus_Name.length > 0 && address1.length > 0 && address2.length > 0 && mobileNumber.length == 10 && deliverycity.length > 0 &&
      deliveryState.length > 0 && pincode.length > 0 && GSTNumber.length == 15
    ) {
      let temp = {
        "N": name,
        "b_name": b_Name,
        "email": email,
      }
      let temp1 = []
      if (AddnewAddressState == false && User.delivery_address == null) {
        temp1 = [{
          "CusName": cus_Name,
          "Ad1": address1,
          "Ad2": address2,
          "CusMobile": mobileNumber,
          "CusCity": deliverycity,
          "CusState": deliveryState,
          "pincode": pincode,
          "GSTNumber": GSTNumber,
          "Position": 0
        }]
      }
      else if (User.delivery_address != null && AddnewAddressState == false) {
        temp1 = [...JSON.parse(User.delivery_address)]
        let Index = JSON.parse(User.delivery_address)[0].Position
        temp1[Index].CusName = cus_Name
        temp1[Index].Ad1 = address1
        temp1[Index].Ad2 = address2
        temp1[Index].CusMobile = mobileNumber
        temp1[Index].CusCity = deliverycity
        temp1[Index].CusState = deliveryState
        temp1[Index].GSTNumber = GSTNumber

      }
      else {
        temp1 = [...JSON.parse(User.delivery_address)]
        temp1[0].Position = JSON.parse(User.delivery_address).length;
        temp1.push({
          "CusName": cus_Name,
          "Ad1": address1,
          "Ad2": address2,
          "CusMobile": mobileNumber,
          "CusCity": deliverycity,
          "CusState": deliveryState,
          "pincode": pincode,
          "GSTNumber": GSTNumber,
        })
      }
      const data1 = {
        "address": JSON.stringify(temp),
        "delivery_address": JSON.stringify(temp1),
        "updatedDate": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' '
          + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds()
      }
      try {
        const response = await UpdateTheData(User.id, data1)
        if (response.status == 200) {
          console.log(response.data);
          dispatch(TestUserFunction({ type: 'address', value: JSON.stringify(temp) }))
          dispatch(TestUserFunction({ type: 'delivery_address', value: JSON.stringify(temp1) }))
          alert('Successfully Updates')
          setAddnewAddressState(false)
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      alert('Fill The Billing Details')
    }
  }
  const sidebarShow = useSelector(e => e.sidebarStatus.initialValue)


  // Delivery Address
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [mobileNumber, setMobileNumber] = useState(0)
  const [deliverycity, setDeliveryCity] = useState('')
  const [deliveryState, setDeliveryState] = useState('')
  const [pincode, setPincode] = useState(0)
  const [GSTNumber, setGSTNumber] = useState('')

  const [ChooseAddressState, setChooseAddressState] = useState(false)
  const [AddnewAddressState, setAddnewAddressState] = useState(false)

  async function PatchingTheSelectedAddress(index) {
    let temp = [...JSON.parse(User.delivery_address)]
    temp[0].Position = index;
    const data1 = {
      "delivery_address": JSON.stringify(temp),
      "updatedDate": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' '
        + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds()
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      console.log(response);
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'delivery_address', value: JSON.stringify(temp) }))
        setCus_Name(JSON.parse(User.delivery_address)[index].CusName)
        setAddress1(JSON.parse(User.delivery_address)[index].Ad1)
        setAddress2(JSON.parse(User.delivery_address)[index].Ad2)
        setMobileNumber(JSON.parse(User.delivery_address)[index].CusMobile)
        setDeliveryCity(JSON.parse(User.delivery_address)[index].CusCity)
        setDeliveryState(JSON.parse(User.delivery_address)[index].CusState)
        setPincode(JSON.parse(User.delivery_address)[index].pincode)
        setGSTNumber(JSON.parse(User.delivery_address)[index].GSTNumber)
        setChooseAddressState(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function DeleteTheAddress(_index) {
    let temp = [...JSON.parse(User.delivery_address)]
    temp[0].Position = 0;
    setCus_Name(JSON.parse(User.delivery_address)[0].CusName)
    setAddress1(JSON.parse(User.delivery_address)[0].Ad1)
    setAddress2(JSON.parse(User.delivery_address)[0].Ad2)
    setMobileNumber(JSON.parse(User.delivery_address)[0].CusMobile)
    setDeliveryCity(JSON.parse(User.delivery_address)[0].CusCity)
    setDeliveryState(JSON.parse(User.delivery_address)[0].CusState)
    setPincode(JSON.parse(User.delivery_address)[0].pincode)
    setGSTNumber(JSON.parse(User.delivery_address)[0].GSTNumber)
    temp.splice(_index, 1)
    console.log(temp);
    const data1 = {
      "delivery_address": JSON.stringify(temp),
      "updatedDate": new Date().getUTCFullYear() + '-' + (new Date().getUTCMonth() + 1) + '-' + new Date().getUTCDate() + ' '
        + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds()
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      console.log(response);
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'delivery_address', value: JSON.stringify(temp) }))
        setChooseAddressState(false)
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const [editOption, setEditOption] = useState(true)




  async function CommentFunction(_obj, idx) {
    let temp = [...JSON.parse(User.cart)]
    const cart = temp.find(e => e.I == _obj.I);
    cart.C = comment[idx].toString()
    const data1 = {
      "cart": temp.length == 0 ? null : JSON.stringify(temp)
    }
    try {
      const response = await UpdateTheData(User.id, data1)
      if (response.status == 200) {
        dispatch(TestUserFunction({ type: 'cart', value: temp.length == 0 ? null : JSON.stringify(temp) }))
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div className="mainLayout relative">
      <div className="columnHolder lg:w-[40%] md:w-full sm:w-full w-full self-center py-[20px]">
        {user1.showStickyHeader == 0 ? (
          <div className="rowHolder w-[90%] self-center px-[0%]">
            <div className="columnHolder bg-white shadow-md shadow-grayColor rounded-md p-4 items-center">
              <div className="text-lg lg:text-xl font-extrabold text-left">
                ₹{returnTheQtyCost("Cost")}
              </div>
              <div className="font-semibold text-H1TextColor text-sm lg:text-lg">
                {returnTheQtyCost("Qty")} Items
              </div>
            </div>
          </div>
        ) : null}
        <div className="rowHolder w-[90%] mt-[2%] round-lg self-center rounded-md bg-white shadow-md shadow-grayColor py-5 items-center px-5">
          <div className="columnHolder">
            <div className=" text-left font-bold text-lg lg:text-xl">
              Have a question?
            </div>
            <div className="text-xs text-H1TextColor text-left">Chat with us</div>
          </div>
          <div onClick={() => window.open("https://web.whatsapp.com/", "_blank")} className="rowHolder cursor-pointer gap-2 bg-[#eff7f0] py-2 px-4 rounded-3xl justify-center  items-center ">
            <img src={WhatsappIcon} className="w-6 h-6" />
            <div className="text-sm font-semibold">Chat Now</div>
          </div>
        </div>

        <div className="columnHolder w-[90%] self-center  bg-white shadow-lg rounded-lg py-4 mt-5 px-5">
          <div className=" text-left text-black font-bold text-lg lg:text-xl ">
            Your order items
          </div>
          <div className="gridHolderCart ">
            {User.cart != null
              ? JSON.parse(User.cart).map((item, index) => item.V == VendorData ?
                <div
                  key={index}
                  className="w-full min-h-[100px] columnHolder py-[2%] justify-center items-center border-b-2">
                  <div className="rowHolder px-[0%] justify-between  self-center w-[98%]">
                    <div className="rowHolder justify-start w-full  gap-3">
                      <img
                        src={'https://storage.googleapis.com/cloudsb/ProductImage/' + item.T}
                        className="squareImages w-[75px] h-[75px]"
                      />
                      <div className="columnHolder">
                        <div className="text-sm lg:text-lg font-bold text-H1TextColor">{item.N}</div>
                        <div className="lg:text-base text-sm font-semibold text-black">{item.Q} items</div>
                        <div className="rowHolder mt-3 w-[120px]  justify-center items-center border-2 border-ButtonColor rounded-lg">
                          <div
                            className="shortContent p-1 border-r-2 border-r-ButtonColor flex flex-col items-center justify-center h-full w-[30%]   rounded-l-md cursor-pointer font-semibold text-black bg-[#D3D3D3]"
                            onClick={() =>
                              QuantityManagement(item, "Dec")
                            }
                          >
                            -
                          </div>
                          <div className="text-center font-semibold  w-[40%]">
                            {item.Q}
                          </div>
                          <div
                            className="shortContent flex flex-col items-center justify-center h-full w-[30%] rounded-r-md cursor-pointer font-semibold text-white bg-ButtonColor hover:bg-HoverColor border-l-2 border-l-ButtonColor"
                            onClick={() => QuantityManagement(item, "Inc")}
                          >
                            +
                          </div>
                        </div>
                        {/* <div
                          className="shortContent text-red-600 cursor-pointer my-[2px]"
                          onClick={() => onRemoveItemClicked(item)}
                        >
                          Remove
                        </div> */}
                      </div>
                    </div>
                    <div className="heading font-bold text-ButtonColor">
                      ₹{item.Q * item.P}
                    </div>
                  </div>
                  <div className="rowHolder gap-2 border border-[#D3D3D3] mt-3 focus:border-ButtonColor w-[98%] self-center items-center px-2 flex flex-row rounded-md ">
                    {/* <img src={PenIcon} className="squareIcons" /> */}
                    <RiPencilFill className="text-ButtonColor w-5 h-5" />
                    <input
                      type="text"
                      className="flex-1 h-full p-2 font-normal text-H1TextColor outline-none text-sm lg:text-lg"
                      placeholder="Type a message"
                      value={comment[index]}
                      onChange={(e) => {
                        if (e.target.value.length < 30) {
                          handleCommentValueChange(index, e.target.value)
                        }
                      }}
                    />
                    <button onClick={() => {
                      if (comment[index].length > 0)
                        CommentFunction(item, index)
                    }} className=" text-white bg-ButtonColor hover:bg-HoverColor duration-150 rounded-md h-full px-4 py-1 2xl:text-[16px] xl:text-[16px] lg:text-[16px] text-[12px]">
                      SEND
                    </button>
                  </div>
                </div> : null
              )
              : null}
          </div>
        </div>
        <div className="rowHolder  w-[90%] self-center  bg-white shadow-lg rounded-lg py-5 mt-5 px-5">
          <div className="lg:text-xl text-base font-extrabold text-black">Total</div>
          <div className="lg:text-xl text-base font-extrabold text-black">
            ₹{returnTheQtyCost("Cost")}
          </div>
        </div>
        <div className="columnHolder bg-white mt-[2%] w-[90%] rounded-lg self-center shadow-md p-5">
          <div className="flex flex-row items-center justify-between">
            <div className="text-left text-black font-bold text-lg lg:text-xl">Billing Details</div>
            <div onClick={() => setEditOption(!editOption)}>
              {editOption ? <BiEditAlt onClick={() => setEditOption(false)} className="w-5 h-5" /> : <MdCancel onClick={() => setEditOption(true)} className="w-5 h-5" />}
            </div>
          </div>
          <div className="lg:text-base text-sm text-black text-left mt-3">Name*</div>
          <input
            type="text"
            value={name}
            disabled={editOption}
            onChange={(e) => setName(e.target.value)}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your Name"
          />

          <div className="lg:text-base text-sm text-black text-left mt-3">Business Name*</div>
          <input
            value={b_Name}
            disabled={editOption}
            onChange={(e) => setB_Name(e.target.value)}
            type="text"
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Enter business name"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">Mobile Number*</div>
          <div className="rowHolder p-[0%]">
            <div className="lg:text-base text-sm text-black text-left mt-2">{User.mobileNumber}</div>
            {/* <div className="text-base  text-left cursor-pointer text-ButtonColor underline ">
              Change Number
            </div> */}
          </div>
          <div className="lg:text-base text-sm text-black text-left mt-3">Email</div>
          <input
            value={email}
            disabled={editOption}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            className="border-2 mt-2 border-[#D3D3D3] text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Enter email"
          />
        </div>
        <div className="columnHolder relative bg-white mt-[2%] w-[90%] rounded-lg self-center mb-5 p-5 shadow-md">
          {ChooseAddressState ?
            <div className="absolute  border rounded-md p-5 left-0 w-full top-0 bg-white shadow-gray-200 shadow-lg">
              <div className="w-full flex flex-col items-end ">
                <Close onClick={() => {
                  setChooseAddressState(false)
                  updateingState()
                }} className="w-5 h-5 cursor-pointer  fill-ButtonColor" />
              </div>
              <div onClick={() => {
                setAddnewAddressState(true)
                setCus_Name('')
                setAddress1('')
                setAddress2('')
                setMobileNumber(0)
                setDeliveryCity('')
                setDeliveryState('')
                setPincode(0)
                setGSTNumber('')
                setChooseAddressState(false)
              }} className="flex space-x-2 cursor-pointer flex-row items-center">
                <PlusIcon className="w-5 h-5  fill-ButtonColor" />
                <h1 className="">Add new Address</h1>
              </div>
              <div className="mt-5">
                {
                  User.delivery_address != null && JSON.parse(User.delivery_address).map((e, index) =>
                    <div key={index} className="flex py-2 cursor-pointer border-b flex-row w-full justify-between space-x-3">
                      {JSON.parse(User.delivery_address)[0].Position == index ? <Checked onClick={() => PatchingTheSelectedAddress(index)} className="w-[16px] object-contain h-[16px] fill-ButtonColor mt-1" /> : <img onClick={() => PatchingTheSelectedAddress(index)} className="w-[16px] object-contain h-[16px] mt-1" src={unChecked} />}
                      <div onClick={() => PatchingTheSelectedAddress(index)} className="w-[90%] md:w-[96%]">
                        <h1 className="text-black font-semibold text-base">{name}</h1>
                        <div className="flex flex-row flex-wrap">
                          <h1 className=" text-H1TextColor opacity-80">{e.GSTNumber}</h1>
                          <h1 className=" text-H1TextColor opacity-80">{e.CusName}, {e.Ad1}, {e.Ad2}, {e.CusMobile}, {e.CusCity}, {e.CusState}, {e.pincode}</h1>
                        </div>
                      </div>
                      {index == 0 ? null : <Delete onClick={() => DeleteTheAddress(index)} className="w-5 object-contain h-5   mt-1" />}
                    </div>
                  )
                }
              </div>
            </div> : null}
          {AddnewAddressState ?
            <div className="absolute  border rounded-md  p-5 left-0 w-full top-0 bg-white shadow-gray-200 shadow-lg">
              <div className="w-full absolute right-5 flex flex-col items-end ">
                <Close onClick={() => {
                  setAddnewAddressState(false)
                  updateingState()
                }} className="w-5 h-5 cursor-pointer  fill-ButtonColor" />
              </div>
              <div className="w-full">
                <h1 className="text-left mb-3 text-black font-bold text-lg lg:text-xl">New Address</h1>
                <div className="lg:text-base text-sm text-black text-left ">Customer Name*</div>
                <input
                  value={cus_Name}
                  disabled={editOption}
                  onChange={(e) => setCus_Name(e.target.value)}
                  type="text"
                  className="border-2 w-full mt-2 border-[#D3D3D3] text-sm lg:text-base outline-0 p-2 rounded-md"
                  placeholder="Enter customer name"
                />
                <div className="lg:text-base text-sm text-black text-left mt-3">Address 1*</div>
                <input
                  type="text"
                  value={address1}
                  disabled={editOption}
                  onChange={(e) => setAddress1(e.target.value)}
                  className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                  placeholder="Please Enter Your Building No, Building Name"
                />
              </div>
              <div className="lg:text-base text-sm text-black text-left mt-3">Address 2*</div>
              <input
                type="text"
                value={address2}
                disabled={editOption}
                onChange={(e) => setAddress2(e.target.value)}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter your Details"
              />
              <div className="lg:text-base text-sm text-black text-left mt-3">Second Mobile Number*</div>
              <input
                type="number"
                value={mobileNumber}
                disabled={editOption}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    setMobileNumber(e.target.value)
                  }
                }}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter Your City"
              />
              <div className="lg:text-base text-sm text-black text-left mt-3">City*</div>
              <input
                type="text"
                value={deliverycity}
                disabled={editOption}
                onChange={(e) => setDeliveryCity(e.target.value)}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter Your City"
              />
              <div className="lg:text-base text-sm text-black text-left mt-3">State*</div>
              <input
                type="text"
                value={deliveryState}
                disabled={editOption}
                onChange={(e) => setDeliveryState(e.target.value)}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter Your State"
              />
              <div className="lg:text-base text-sm text-black text-left mt-3">Pincode*</div>
              <input
                type="number"
                value={pincode}
                disabled={editOption}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    setPincode(e.target.value)
                  }
                }}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter Your Pincode"
              />
              <div className="lg:text-base text-sm text-black text-left mt-3">GST Number*</div>
              <input
                type="text"
                min={0}
                value={GSTNumber}
                disabled={editOption}
                onChange={(e) => {
                  if (e.target.value.length <= 15) {
                    setGSTNumber(e.target.value)
                  }
                }}
                className="border-2 w-full mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
                placeholder="Please Enter Your Gst Number"
              />
            </div> : null}
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-left text-black font-bold text-base lg:text-xl">Delivery Address</h1>
            <h1 onClick={() => setChooseAddressState(true)} className="font-semibold cursor-pointer text-ButtonColor text-sm lg:text-base">Choose Address</h1>
          </div>
          <div className="lg:text-base text-sm text-black text-left mt-3">Customer Name*</div>
          <input
            value={cus_Name}
            disabled={editOption}
            onChange={(e) => setCus_Name(e.target.value)}
            type="text"
            className="border-2 mt-2 border-[#D3D3D3] text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Enter customer name"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">Address 1*</div>
          <input
            type="text"
            value={address1}
            disabled={editOption}
            onChange={(e) => setAddress1(e.target.value)}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your Building No, Building Name"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">Address 2*</div>
          <input
            type="text"
            value={address2}
            disabled={editOption}
            onChange={(e) => setAddress2(e.target.value)}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter your Details"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">Second Mobile Number*</div>
          <input
            type="number"
            value={mobileNumber}
            disabled={editOption}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setMobileNumber(e.target.value)
              }
            }}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your City"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">City*</div>
          <input
            type="text"
            value={deliverycity}
            disabled={editOption}
            onChange={(e) => setDeliveryCity(e.target.value)}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your City"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">State*</div>
          <input
            type="text"
            value={deliveryState}
            disabled={editOption}
            onChange={(e) => setDeliveryState(e.target.value)}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your State"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">Pincode*</div>
          <input
            type="number"
            value={pincode}
            disabled={editOption}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setPincode(e.target.value)
              }
            }}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your Pincode"
          />
          <div className="lg:text-base text-sm text-black text-left mt-3">GST Number*</div>
          <input
            type="text"
            min={0}
            value={GSTNumber}
            disabled={editOption}
            onChange={(e) => {
              if (e.target.value.length <= 15) {
                setGSTNumber(e.target.value)
              }
            }}
            className="border-2 mt-2 border-[#D3D3D3]  text-sm lg:text-base outline-0 p-2 rounded-md"
            placeholder="Please Enter Your Gst Number"
          />
        </div>
        {editOption == false ? <div onClick={() => PatchingTheAddress()} className="flex flex-row mb-20 cursor-pointer rounded-md w-max self-center items-center justify-center p-2 bg-ButtonColor hover:bg-HoverColor">
          <h1 className="text-white tracking-wider font-semibold">Save Address</h1>
        </div> : null}
      </div>
      {sidebarShow == 1 ? <Sidebar /> : null}
      {User.cart != null ? <FloatingFooterConfirmOrder /> : null}
    </div>
  );
}
export default Cart;
