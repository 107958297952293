import Bags from "../Assets/CataloguePictures/Bags.jpg";
import BigPouches from "../Assets/CataloguePictures/BigPouches.jpg";
import Diaries from "../Assets/CataloguePictures/Diaries.jpg";
import Erasers from "../Assets/CataloguePictures/Erasers.jpg";
import ExamBoards from "../Assets/CataloguePictures/ExamBoards.jpg";
import FancyHairCombs from "../Assets/CataloguePictures/FancyHairCombs.jpg";
import FancyMirrors from "../Assets/CataloguePictures/FancyMirrors.jpg";
import Folders from "../Assets/CataloguePictures/Folders.jpg";
import FridgeMagnets from "../Assets/CataloguePictures/FridgeMagnets.jpg";
import GiftSets from "../Assets/CataloguePictures/GiftSets.jpg";
import HighlightersAndMarkers from "../Assets/CataloguePictures/HighlightersAndMarkers.jpg";
import Keychains from "../Assets/CataloguePictures/Keychains.jpg";
import KidsTabletsAndLearningToys from "../Assets/CataloguePictures/KidsTabletsAndLearningToys.jpg";
import LampsAndClocks from "../Assets/CataloguePictures/LampsAndClocks.jpg";
import Lunchbox from "../Assets/CataloguePictures/Lunchbox.jpg";
import MixItems from "../Assets/CataloguePictures/MixItems.jpg";
import OfficeDiaries from "../Assets/CataloguePictures/OfficeDiaries.jpg";
import PencilBoxes from "../Assets/CataloguePictures/PencilBoxes.jpg";
import Pens from "../Assets/CataloguePictures/Pens.jpg";
import PiggyBanks from "../Assets/CataloguePictures/PiggyBanks.jpg";
import Sharpners from "../Assets/CataloguePictures/Sharpners.jpg";
import Sippers from "../Assets/CataloguePictures/Sippers.jpg";
import SlimeAndClay from "../Assets/CataloguePictures/SlimeAndClay.jpg";
import StickyNotes from "../Assets/CataloguePictures/StickyNotes.jpg";
import WashiTapes from "../Assets/CataloguePictures/WashiTapes.jpg";
export const CatalogueCollection = [
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
  {
    name: "Keychains",
    imageUrl: Keychains,
  },
  {
    name: "Mix Items",
    imageUrl: MixItems,
  },
  {
    name: "Slime and Clay",
    imageUrl: SlimeAndClay,
  },
  {
    name: "Bags",
    imageUrl: Bags,
  },
];
export const ProductsCollections = [
  {
    catalogueName: "Pens",
    name: "Panda Premium Click Pen 002",
    minQty: 10,
    stepQty: 10,
    pricePerItem: 120,
    imageUrl: Pens,
  },
  {
    catalogueName: "Pens",
    name: "Hello Kitty Premium Click Pen 63052",
    minQty: 56,
    stepQty: 10,
    pricePerItem: 120,
    imageUrl: Bags,
  },
  {
    catalogueName: "Pens",
    name: "Space Premium Click Pen 0023",
    minQty: 36,
    stepQty: 10,
    pricePerItem: 120,
    imageUrl: MixItems,
  },
  {
    catalogueName: "Pens",
    name: "Basketball Premium Click Pen 0007",
    minQty: 13,
    stepQty: 10,
    pricePerItem: 120,
    imageUrl: SlimeAndClay,
  },
  {
    catalogueName: "Pens",
    name: "Hello Kitty Premium Click Pen 63052",
    minQty: 56,
    stepQty: 10,
    pricePerItem: 120,
    imageUrl: Bags,
  },
];
