import { createSlice } from "@reduxjs/toolkit";

const TestuserSlice = createSlice({
    name: 'user',
    initialState: {
        Initialuser:
        {
            id: null,
            name: null,
            mobileNumber: null,
            delivery_address:null,
            address: null,
            cart: null
        }
    },
    reducers: {
        TestUserFunction: (state, params) => {
            switch (params.payload.type) {
                case "id":
                    state.Initialuser.id = params.payload.value
                    break;
                case "name":
                    state.Initialuser.name = params.payload.value
                    break;
                case "mobileNumber":
                    state.Initialuser.mobileNumber = params.payload.value
                    break;
                case "address":
                    state.Initialuser.address = params.payload.value
                    break;
                case "delivery_address":
                    state.Initialuser.delivery_address = params.payload.value
                    break;
                case "cart":
                    state.Initialuser.cart = params.payload.value
                    break;
                default:
                    break;
            }
        }
    }
})
export const { TestUserFunction } = TestuserSlice.actions
export default TestuserSlice.reducer