import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./UserSlice";
import TestUserReducer from "./TestuserSlice";
import CategoryReducer from "./CategorySlice";
import ProductsReducer from "./ProductsSlice";
import SidebarReducer from "./SidebarSlice";

export const store = configureStore({
  reducer: {
    userComponent: UserReducer,
    testUser:TestUserReducer,
    categorydata:CategoryReducer,
    productdata:ProductsReducer,
    sidebarStatus:SidebarReducer
  },
});
