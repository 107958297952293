import { createSlice } from "@reduxjs/toolkit";

const CategorySlice = createSlice({
    name: 'category',
    initialState: {
        initialCategory: null
    },
    reducers: {
        PatchingCategoryData: (state, params) => {
            state.initialCategory = params.payload
        }
    }
})

export const {PatchingCategoryData}=CategorySlice.actions
export default CategorySlice.reducer